import React, { Suspense, lazy } from "react";
import { ThemeProvider } from "styled-components";
import { RootDiv } from "./custom-theme";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { MobileMainViewContainer } from "./moContainer";
import routes from "./routes";
import { lightTheme } from "./components/GlobalStyle/GlobalStyle";
import AsonicDndContainer from "./components/asonic-table/asonic-dnd-container";

const ElectronMainPage = React.lazy(() => import("./components/electron"));

const ChartContainer = React.lazy(
  () => import("./components/main-view/ChartContainer")
);
const MainViewContainer = lazy(
  () => import("./containers/main-view/MainViewContainer")
);
// const ScreenShareContainer = lazy(() =>
//   import("./containers/WebRTC/ScreenShareContainer/ScreenShareContainer")
// );
const SignInContainer = lazy(
  () => import("./containers/sign-in/SignInContainer")
);
const RommsContainer = lazy(() => import("./containers/WebRTC/RoomsContainer"));
// DatePicker에서 달력이나 시간 ui를 custom하기위한 function

const App = () => {
  return (
    <ThemeProvider theme={lightTheme}>
      <AsonicDndContainer>
        <RootDiv>
          <Suspense fallback={<div>Loading...</div>}>
            <BrowserRouter>
              <Switch>
                {/* <Route
                    path={`${
                      routes.pageRoutes.main
                    }${routes.pageRoutes.room()}`}
                    component={ScreenShareContainer}
                  /> */}
                <Route
                  path={routes.pageRoutes.electron}
                  component={ElectronMainPage}
                />
                <Route
                  path={`${routes.pageRoutes.main}${routes.pageRoutes.chart}`}
                  component={ChartContainer}
                />
                <Route
                  path={`${routes.pageRoutes.main}${routes.pageRoutes.rtc}`}
                  component={RommsContainer}
                  exact
                />
                <Route
                  path={routes.pageRoutes.main}
                  component={MainViewContainer}
                />
                <Route
                  path={routes.pageRoutes.noAuth}
                  component={SignInContainer}
                />
                <Route
                  path={`/m${routes.pageRoutes.main}`}
                  component={MobileMainViewContainer}
                />
                <Route
                  exact
                  path={routes.pageRoutes.root}
                  component={SignInContainer}
                />
              </Switch>
            </BrowserRouter>
          </Suspense>
        </RootDiv>
      </AsonicDndContainer>
    </ThemeProvider>
  );
};

export default App;
