const ROOT_PATH = "/";
const MAINVIEW_PATH = "/mainview/";
const NOAUTH = "/noauth/";
const WEB_SERVER_ADDR =
  process.env.REACT_APP_WEB_SERVER || "http://localhost:18081";

const HOME = `${MAINVIEW_PATH}home`;
const PERSONAL = `${MAINVIEW_PATH}personal`;
const WORKING_MANAGEMENT_INFORMATION = `${MAINVIEW_PATH}work_management_information`;
const SETTINGS = `${MAINVIEW_PATH}settings`;

const RTC = "rtc";
const ROOM = (id?: string) => `${RTC}:${id}`;
const CHART = "chart";
const CALENDAR = `${MAINVIEW_PATH}calendar`;
const SUPER_ADMIN_SETTINGS = `${MAINVIEW_PATH}super-admin-settings`;
const VEHICLE_DRIVING_MANAGEMENT = `${MAINVIEW_PATH}vehicle-driving-management`;
const RESERVATION_MANAGEMENT = `${MAINVIEW_PATH}reservation-management`;

const ELECTRON = `/electron`;
const ELECTRON_SERVICE_STATUS = `${ELECTRON}/service-status`;
const ELECTRON_CALENDAR = `${ELECTRON}/calendar`;

const page = {
  root: ROOT_PATH,
  main: MAINVIEW_PATH,
  home: HOME,
  personal: PERSONAL,
  workingManagementInformation: WORKING_MANAGEMENT_INFORMATION,
  settings: SETTINGS,
  noAuth: NOAUTH,
  room: ROOM,
  chart: CHART,
  rtc: RTC,
  webServerAddr: WEB_SERVER_ADDR,
  calendar: CALENDAR,
  superAdminSettings: SUPER_ADMIN_SETTINGS,
  vehicleDrivingManagement: VEHICLE_DRIVING_MANAGEMENT,
  reservationManagement: RESERVATION_MANAGEMENT,
  electron: ELECTRON,
  electronServiceStatus: ELECTRON_SERVICE_STATUS,
  electronCalendar: ELECTRON_CALENDAR
};

export default page;
