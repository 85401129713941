import { createGlobalStyle, DefaultTheme } from "styled-components";

export const colors = {
  white: "#ffffff",
  darkWhite: "#FAFAFA",
  grey: "#F2EDEB",
  darkGrey: "#7c7c7c",
  black: "rgb(27, 25, 25)",
  veryDarkBlue: "#0066B3",
  darkBlue: "#0984e3",
  skyBlue: "#74b9ff",
  purpleBlue: "#3742fa",
  tomato: "#ff7675",
  darkRed: "#eb3b5a",
  green: "#0be881",
  darkGreen: "#05c46b",
  yellow: "#ffeaa7",
  darkYellow: "#ff9f43"
};

export const lightTheme: DefaultTheme = {
  colors,
  bgColor: colors.darkWhite,
  fontColor: colors.black,
  titleBarColor: colors.veryDarkBlue,
  borderColor: colors.darkGrey
};

export const darkTheme: DefaultTheme = {
  colors,
  bgColor: colors.black,
  fontColor: colors.darkWhite,
  titleBarColor: colors.veryDarkBlue,
  borderColor: colors.darkGrey
};

const GlobalStyle = createGlobalStyle`
  input, textarea, select {
    padding: 2px;
    resize: none;
  }
  body {
    box-sizing: border-box;
    padding: 0px;
    margin: 0;
    font-size:12px;
    font-family: 'Nanum Gothic', sans-serif;
  }
`;

export default GlobalStyle;
