import styled from "styled-components";
import { Icon } from "@iconify/react";

interface IProps {
  icon: any;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  color?: string;
}

const Container = styled.div<{ disabled?: boolean }>`
  cursor: pointer;
  color: ${props => {
    if (props.disabled) {
      return props.theme.colors.darkGrey;
    }
    if (props.color) {
      return props.color;
    }
    return props.theme.colors.darkBlue;
  }};
  :active {
    transform: ${props => (props.disabled ? "" : "scale(0.95)")};
  }
  :hover {
    color: ${props =>
      props.disabled
        ? props.theme.colors.darkGrey
        : props.theme.colors.skyBlue};
  }
`;

function AsonicIconButton({ icon, disabled, onClick, color }: IProps) {
  return (
    <Container
      disabled={disabled}
      onClick={!disabled ? onClick : undefined}
      color={color}
    >
      <Icon icon={icon} />
    </Container>
  );
}

export default AsonicIconButton;
