import styled from "styled-components";
import { Icon } from "@iconify/react";
import BlockIcon from "@iconify-icons/icon-park/blocks-and-arrows";

interface IProps {
  title: string;
}

const TitleBar = styled.div`
  display: flex;
  padding: 0px 5px 0px 5px;
  font-weight: bold;
  border-bottom: 2px solid ${props => props.theme.colors.black};
  gap: 10px;
`;

const Title = styled.span``;

function ContentTitleBar({ title }: IProps) {
  return (
    <TitleBar>
      <Icon icon={BlockIcon} />
      <Title>{title}</Title>
    </TitleBar>
  );
}

export default ContentTitleBar;
