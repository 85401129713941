import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationCircle,
  faTimes,
  faExclamationTriangle,
  faInfoCircle
} from "@fortawesome/free-solid-svg-icons";
import { useCallback } from "react";

interface IProps {
  messageTypes: MessageTypes;
  message?: string;
  isOpen: boolean;
  handleIsOpen: (value: boolean) => void;
  widget?: boolean;
}

export enum MessageTypes {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  WARNING = "WARNING",
  INFO = "INFO"
}

export const SuccessType = {
  title: "성공",
  iconColor: "#20bf6b",
  backgroundColor: "#b8e994",
  icon: faCheckCircle
};

export const ErrorType = {
  title: "에러",
  iconColor: "#ee5253",
  backgroundColor: "#ffcccc",
  icon: faExclamationCircle
};

export const WarningType = {
  title: "경고",
  iconColor: "#fd9644",
  backgroundColor: "#fad390",
  icon: faExclamationTriangle
};
export const InfoType = {
  title: "정보",
  iconColor: "#45aaf2",
  backgroundColor: "#acdbfd",
  icon: faInfoCircle
};

const Container = styled.div<{
  widget?: boolean;
  backgroundcolor: string;
  isOpen: boolean;
}>`
  position: fixed;
  display: flex;
  justify-content: center;
  transition: all 0.3s ease-in-out, visibility 0.2s ease-in-out;
  flex: 1;
  left: ${props => (props.widget ? "10vw" : "40vw")};
  bottom: ${props => (props.isOpen ? "2vw" : "0vw")};
  background-color: ${props => props.backgroundcolor};
  flex-direction: column;
  border-radius: 7px;
  width: ${props => (props.widget ? "80vw" : "20vw")};
  box-shadow: ${props => `30px 0px 0px -20px ${props.color} inset`};
  -webkit-box-shadow: ${props => `30px 0px 0px -20px ${props.color} inset`};
  -moz-box-shadow: ${props => `30px 0px 0px -20px ${props.color} inset`};
  visibility: ${props => (props.isOpen ? "visible" : "hidden")};
  z-index: 10;
`;

const TitleContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-around;
  align-items: center;
`;

const TitleBar = styled.div`
  display: flex;
  align-items: center;
  flex: 6;
`;

const Title = styled.h3`
  margin-left: 2%;
`;

const Icon = styled(FontAwesomeIcon)<{ messagetypes: MessageTypes }>`
  margin-left: 5%;
  background-color: white;
  border-radius: 50%;
  clip-path: ${props =>
    props.messagetypes === MessageTypes.WARNING
      ? "polygon(50% 0%, 0% 100%, 100% 100%)"
      : "none"};
`;

const Xicon = styled(FontAwesomeIcon)`
  cursor: pointer;
  flex: 1;
`;

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 0% 3% 3% 3%;
`;

const Content = styled.span`
  font-size: 13px;
`;

const ToastMessage = <P extends IProps>({
  messageTypes,
  message,
  isOpen,
  handleIsOpen,
  widget
}: P) => {
  const makeTypes = useCallback(() => {
    switch (messageTypes) {
      case MessageTypes.SUCCESS:
        return SuccessType;
      case MessageTypes.ERROR:
        return ErrorType;
      case MessageTypes.WARNING:
        return WarningType;
      case MessageTypes.INFO:
        return InfoType;
    }
  }, [messageTypes]);
  return (
    <Container
      backgroundcolor={makeTypes().backgroundColor}
      color={makeTypes().iconColor}
      isOpen={isOpen}
      widget={widget}
    >
      <TitleContainer>
        <TitleBar>
          <Icon
            icon={makeTypes().icon}
            size="2x"
            color={makeTypes().iconColor}
            messagetypes={messageTypes}
          />
          <Title>{makeTypes().title}</Title>
        </TitleBar>
        <Xicon
          icon={faTimes}
          size="2x"
          color={makeTypes().iconColor}
          onClick={() => {
            handleIsOpen(false);
          }}
        />
      </TitleContainer>
      <ContentContainer>
        <Content>{message}</Content>
      </ContentContainer>
    </Container>
  );
};

export default ToastMessage;
