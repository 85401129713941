import styled from "styled-components";

interface IProps {
  backgroundColor?: string;
}

export default styled.button`
  border-radius: 4px;
  height: 30px;
  cursor: ${props => (props.disabled ? "default" : "pointer")};
  background-color: ${(props: IProps) => props.backgroundColor ?? "#2196f3"};
  color: white;
  border: none;
  outline: none;
  padding: 6px;
  min-width: 100px;
  :hover {
    background-color: #accbff;
  }
  :active {
    transform: scale(0.98);
  }
  font-family: "Gothic", sans-serif;
  opacity: ${props => (props.disabled ? "0.2" : "1")};
`;
